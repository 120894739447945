import React, { Suspense } from 'react';

import {
  PageWrapper,
  Panel,
  Flex,
  Heading3,
  LoadingContainer,
  useNotification,
  LeftHalfLayoutResponsive,
  Accordion
} from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';
import {
  Drivers,
  useQuery,
  useMutation,
  useQueryClient
} from '@ubisend/pulse-hooks';

import { updateGenerativeDriver } from '../api/index';
import { Resource, Sitemap, Prompt } from '../Components/index';

const GenerativeAi = () => {
  const { showSuccess, showError } = useNotification();
  const { hasPermission } = useAuth();

  const queryClient = useQueryClient();
  const allQuery = useQuery('generative-ai/all');
  const currentQuery = useQuery('generative-ai');
  const mutation = useMutation(updateGenerativeDriver);

  const handleDriver = async driver => {
    try {
      await mutation.mutateAsync(driver.id);
      await queryClient.invalidateQueries('generative-ai');
      showSuccess(
        `Successfully made ${driver.name} your Generative AI provider.`
      );
    } catch (error) {
      showError('Failed to add driver: ' + error.message);
    }
  };

  // Temp hide Provider driver until more than one
  const showProvider = false;

  const isLoading = allQuery.isLoading || currentQuery.isLoading;

  return (
    <PageWrapper
      header="Generative AI"
      subheader="Configure your generative AI">
      {isLoading ? (
        <LoadingContainer />
      ) : (
        <LeftHalfLayoutResponsive>
          <Panel>
            {showProvider && (
              <Flex col>
                <Heading3 mbSm htmlFor="action">
                  Provider
                </Heading3>

                <Suspense fallback={<LoadingContainer />}>
                  <Drivers
                    type="single"
                    required
                    value={!isLoading && currentQuery.data?.data?.id}
                    drivers={allQuery.data.data}
                    handleClick={handleDriver}
                    canEditDriver={() => false}
                    canEnableDriver={() =>
                      hasPermission('edit generativeai providers')
                    }
                    canDisableDriver={() =>
                      hasPermission('edit generativeai providers')
                    }
                  />
                </Suspense>
              </Flex>
            )}
            <Heading3 mbSm>Resources</Heading3>

            <Accordion header={'Document'}>
              <Resource />
            </Accordion>
            <br />
            <Accordion header={'Website'}>
              <Sitemap />
            </Accordion>

            <Prompt />
          </Panel>
        </LeftHalfLayoutResponsive>
      )}
    </PageWrapper>
  );
};

export default GenerativeAi;
